
























































































import Vue from "vue";
import Component from "vue-class-component";
import {
  commitDrawer,
  commitSnackbarText,
  dispatchRestoreState,
  dispatchSaveState,
  readDrawer,
  readSnackbarText,
} from "./store";

const SMALL_TIMEOUT = 1250;
const LARGE_TIMEOUT = 3000;

@Component({ name: "App" })
export default class extends Vue {
  private snackbarEnabled: boolean = false;
  private snackbarText: string = "";
  private drawer: boolean = false;
  private seenSnackbarTexts: { [x in string]: boolean } = {};
  private timeout: number = SMALL_TIMEOUT;

  public created() {
    this.$watch(() => this.snackbarEnabled, function(snackbarEnabled) {
      if (!snackbarEnabled) {
        commitSnackbarText(this.$store, null);
      }
    });
    this.$watch(() => readSnackbarText(this.$store), function(snackbarText) {
      if (snackbarText !== null) {
        this.snackbarEnabled = true;
        this.snackbarText = snackbarText;

        if (this.seenSnackbarTexts[snackbarText]) {
          this.timeout = SMALL_TIMEOUT;
        } else {
          this.timeout = LARGE_TIMEOUT;
        }
        this.seenSnackbarTexts[snackbarText] = true;
      } else {
        this.snackbarEnabled = false;
        this.snackbarText = "";
      }
    });

    this.$watch(() => this.drawer, function(drawer) {
      commitDrawer(this.$store, drawer);
    });
    this.$watch(() => readDrawer(this.$store), function(drawer) {
      this.drawer = drawer;
    });
  }

  public async mounted() {
    await dispatchRestoreState(this.$store);
  }
}
